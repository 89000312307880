import * as yup from 'yup';


export const commonTransactionTypeMappingMaintenanceValidation: { [x: string]: any; } = {
  docType: yup.string().required("Missing"),
  chargeType: yup.string().required("Missing"),
  adjType: yup.string().required("Missing"),
  transactionType: yup.string().required("Missing"),
};

export const createTransactionTypeMappingMaintenanceValidation: { [x: string]: any; } = {
  ...commonTransactionTypeMappingMaintenanceValidation,
};

export const createTransactionTypeMappingMaintenanceValidationSchema = yup.object().shape(createTransactionTypeMappingMaintenanceValidation);

