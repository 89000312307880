import { AdjustmentSubTypePolicy } from "constants/charge/AdjustmentSubTypePolicy";

export const AdjustmentSubTypeDroOpts = () => {


    const getAdjustmentSubTypeModel = () => {
        let items = [AdjustmentSubTypePolicy.DIFF_LINE, AdjustmentSubTypePolicy.SAME_LINE];

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getAdjustmentSubTypeModel        
    }
}