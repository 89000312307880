import { DocumentTypePolicy } from "constants/document/DocumentTypePolicy";

export const DocumentTypeDroOpts = () => {


    const getDocumentTypeModel = () => {
        let items = [DocumentTypePolicy.INVOICE_TYPE, DocumentTypePolicy.CREDIT_NOTE_TYPE];

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getDocumentTypeModel        
    }
}