import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TransactionTypeMappingMaintenanceProvider } from "presentation/store/TransactionTypeMapping/TransactionTypeMappingMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { TransactionTypeMappingMasterView } from "presentation/view/section/TransactionTypeMapping/TransactionTypeMappingMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TransactionTypeMappingMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.TRANSACTION_TYPE_SETTING_MAINTENANCE}>
        <MessageBarWrapper>
            <TransactionTypeMappingMaintenanceProvider>
                <GridStyles/>
                <TransactionTypeMappingMasterView/>
            </TransactionTypeMappingMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TransactionTypeMappingMaintenanceCont;