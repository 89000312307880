import { TransactionTypeMappingMaintenanceConstant } from "./TransactionTypeMappingMaintenanceConstant";

const SCREEN_CONSTANT = TransactionTypeMappingMaintenanceConstant;
export const INITIAL_TRANSACTION_TYPE_MAPPING_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.DOC_TYPE,
        field: "docType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
        //pinned: "left",
        //checkboxSelection: true,
        //headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 140,
    },
    {
        headerName: SCREEN_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.ADJ_TYPE,
        field: "adjType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 140,
    },
    {
        headerName: SCREEN_CONSTANT.ADJ_SUB_TYPE,
        field: "adjSubType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.TRAS_TYPE,
        field: "transactionType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
]
