import { EMPTY_MASTER_TRANSACTION_TYPE_MAPPINGANY_MODEL } from "presentation/model/TransactionTypeMapping/TransactionTypeMappingMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: TransactionTypeMappingMaintenanceProvider,
    useTracked: useTransactionTypeMappingMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_TRANSACTION_TYPE_MAPPINGANY_MODEL), {concurrentMode: true});
export { TransactionTypeMappingMaintenanceProvider, useTransactionTypeMappingMaintenanceTracked };

