import { TransactionTypeMappingMaintenanceConstant } from "presentation/constant/TransactionTypeMapping/TransactionTypeMappingMaintenanceConstant";
import { useTransactionTypeMappingMaintenanceVM } from "presentation/hook/TransactionTypeMapping/useTransactionTypeMappingMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTransactionTypeMappingMaintenanceTracked } from "presentation/store/TransactionTypeMapping/TransactionTypeMappingMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TransactionTypeMappingEditPanelTitleBar: React.FC = () => {
    const [transactionTypeMappingState] = useTransactionTypeMappingMaintenanceTracked();
    const transactionTypeMappingVM = useTransactionTypeMappingMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = transactionTypeMappingState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        transactionTypeMappingVM.onReset();
    }, [transactionTypeMappingVM]);

    const handleEdit = useCallback(() => {
        transactionTypeMappingVM.onEditClicked();
    }, [transactionTypeMappingVM])

    const handleSave = useCallback(async () => {
        if (!currentEntity.transactionType) {
            messageBarVM.showError("Transaction Type is mandatory.");
            return;
        }
        if (!currentEntity.docType) {
            messageBarVM.showError("Doc Type is mandatory.");
            return;
        }
        if (!currentEntity.chargeType) {
            messageBarVM.showError("Charge Type is mandatory.");
            return;
        }
        if (!currentEntity.adjType) {
            messageBarVM.showError("Adj. Type is mandatory.");
            return;
        }
        if (!currentEntity.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        try {
            transactionTypeMappingVM.onSaveClicked();
            const res = await transactionTypeMappingVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showError(res?.data ?? 'Save data failed.');
            } else {
                transactionTypeMappingVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, transactionTypeMappingVM]);

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: TransactionTypeMappingMaintenanceConstant.CATEGORY },
            { title: TransactionTypeMappingMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!(isEditable || isAdd)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (!(isEditable || isAdd) && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={transactionTypeMappingVM.onClose} />
                </>}
            {((isEditable || isAdd)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={transactionTypeMappingVM.onClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TransactionTypeMappingEditPanelTitleBar);