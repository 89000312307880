import { TransactionTypeMappingRepoImpl } from "domain/repository/TransactionTypeMapping/TransactionTypeMappingRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { useTransactionTypeMappingMaintenanceTracked } from "presentation/store/TransactionTypeMapping/TransactionTypeMappingMaintenanceProvider";
import { TransactionTypeMappingMaintenanceVM } from "presentation/viewModel/TransactionTypeMapping/TransactionTypeMappingMaintenanceVM";
import { useMemo } from "react";

export const useTransactionTypeMappingMaintenanceVM = () => {
    const [, setTransactionTypeMappingMaintenanceState] = useTransactionTypeMappingMaintenanceTracked();
    const transactionTypeMappingMainVM = useMemo(() =>
        TransactionTypeMappingMaintenanceVM({
            dispatch: [setTransactionTypeMappingMaintenanceState],
            repo: TransactionTypeMappingRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
        }), [setTransactionTypeMappingMaintenanceState])

    return transactionTypeMappingMainVM
}

