export const transactionTypeMappingMaintenanceRequiredFieldList: string[] = [
    'transactionTypeMapping', 
];

export const TransactionTypeMappingMaintenanceConstant = {    
    CATEGORY: "Invoice",
    TITLE: "eBIS Transaction Type Setting",
    TRANSACTION_TYPE_MAPPING: "eBIS Transaction Type Setting",
    DOC_TYPE: 'Doc Type',
    CHARGE_TYPE: 'Charge Type',
    SUB_CHARGE_TYPE: 'Sub Charge Type',
    ADJ_TYPE: 'Adj. Type',
    ADJ_SUB_TYPE: 'Adj. Sub Type',
    TRAS_TYPE: 'Transaction Type',
    ACTIVE_IND: 'Active Ind.',
}
