
export interface TransactionTypeMappingEntity {
    id: number,
    priority: number | null,
    effectiveDate: Date | null,
    expiryDate: Date | null,
    confirmedDate: Date | null,
    docType: string | null,
    chargeType: string | null,
    adjType: string | null,
    adjSubType: string | null,
    subChargeType: string | null,
    transactionType: string,
    activeInd: string | null,
    [key: string]: string | number | Date | null | undefined
}

export const EMPTY_TRANSACTION_TYPE_MAPPING_ENTITY: TransactionTypeMappingEntity = {
    id: 0,
    docType: "",
    chargeType: null,
    adjType: "",
    adjSubType: null,
    activeInd: "Y",
    priority: null,
    effectiveDate: null,
    expiryDate: null,
    confirmedDate: null,
    subChargeType: null,
    transactionType: ""
}

